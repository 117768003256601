@import "../../../assets/variables";

.portfolio-list {
  .portfolio-item {
    padding-top: 80px;
    padding-bottom: 80px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .inner {
      display: flex;

      .number {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0px 10px auto;
      }

      h3 {
        margin: 0px;
        p {
          font-family: Roboto;
          font-size: 64px;
          font-weight: 800;
          line-height: 51px;
          letter-spacing: 0em;
          text-align: left;
          text-transform: uppercase;
          margin: 0px;
        }
      }
    }

    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      margin: auto 0px 0px 10px;
      text-transform: uppercase;
    }
  }

  @media (max-width: 767px) {
    .portfolio-item {
      justify-content: flex-start;
      align-items: flex-start;
      padding-top: 40px;
      padding-bottom: 40px;
      flex-direction: column;

      .inner {
        .number {
          margin: 0px 10px 10px 0px;
          font-size: 9px;
        }

        h3 {
          p {
            font-size: 37px;
            line-height: 1;
          }
        }
      }

      p {
        margin: 10px 0px 0px 30px;
        font-size: 12px;
        width: 100%;
      }
    }
  }
}
