.clients-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .client-item {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;

    img {
      max-height: 150px;
    }
  }

  @media (max-width: 767px) {
    .client-item {
      padding: 15px;

      img {
        max-height: 75px;
      }
    }
  }
}
