@import "../../../assets/variables";

.gradient-text {
  background: linear-gradient(
    93.21deg,
    $accent-purple 3.76%,
    $accent-pink 36.27%,
    $accent-blue 78.47%,
    $accent-green 113.25%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
