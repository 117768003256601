@import "../../../assets/variables";

.btn.btn-black {
  @include transition;

  background-color: $color-white !important;
  color: $color-black !important;
  padding: 7px, 11px, 7px, 11px;
  text-transform: uppercase;
  border-radius: $btn-radius;
  font-size: $btn-font-size;

  &:hover {
    background-color: $color-black !important;
    color: $color-white !important;
  }
}

@media (max-width: 767px) {
  .btn.btn-black {
    font-size: 12px;
    background-color: $color-white !important;
    color: $color-black !important;
    margin-top: 100%;

    &:hover {
      background-color: $color-black !important;
      color: $color-white !important;
    }
  }
}
