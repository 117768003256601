/*colors*/
$color-black: #000000;
$color-white: #ffffff;
$line-gray: #1e1e1e;
$accent-purple: #b19afe;
$accent-pink: #f244c6;
$accent-blue: #24abff;
$accent-green: #a0ffe8;

$btn-radius: 40px;
$btn-font-size: 16px;
$border-width: 0.8px;

/*function*/
@mixin transition {
  transition: all 0.5s;
}

@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin animate-gradient {
  background: linear-gradient(
    -45deg,
    $accent-purple 3.76%,
    $accent-pink 36.27%,
    $accent-blue 78.47%,
    $accent-green 113.25%
  );
  background-size: 400% 400%;
  animation: gradient 5s ease infinite;
}
