@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./variables";

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Open Sans", sans-serif;
  font-family: "Roboto", sans-serif;
}

header {
  @include animate-gradient;
  height: 90vh;
  padding-top: 50px;
  text-align: right;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;

    .header-content {
      margin-bottom: 10rem;

      .logo-container {
        margin-right: 6rem;

        img,
        svg {
          max-width: 100%;
        }
      }

      @media (max-width: 767px) {
        .logo-container {
          margin-right: 0px;
          margin-top: 30%;
          margin-bottom: 30%;

          img,
          svg {
            max-width: 100%;
          }
        }
      }

      .header-text {
        margin-right: calc(6rem + 15px);
        font-size: 16px;
        font-weight: 400;
        line-height: 31px;
        letter-spacing: 0.51em;
        text-align: right;
        color: $color-white;
        margin-top: 2rem;
        text-transform: uppercase;
      }

      @media (max-width: 767px) {
        .header-text {
          margin-right: 0px;
          font-size: 12px;
          text-align: center;
          line-height: 23px;
          padding-left: 60px;
          padding-right: 60px;

          br {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      justify-content: center;
      align-items: center;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.content {
  padding-top: 4rem;
  padding-bottom: 4rem;

  p {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  .light-text {
    font-family: Roboto;
    font-size: 36px;
    font-weight: 100;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
  }

  .medium-text {
    font-size: 36px;
    font-weight: 400;
    line-height: 42px;
    letter-spacing: 0.295em;
    text-align: center;
  }

  .large-text {
    font-family: Roboto;
    font-size: 40px;
    font-weight: 900;
    line-height: 47px;
    letter-spacing: 0.295em;
    text-align: center;
    text-transform: uppercase;
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  @media (max-width: 767px) {
    .light-text {
      font-size: 20px;
      line-height: 1.4;
    }
    .large-text {
      margin-top: 4rem;
      margin-bottom: 4rem;
      font-size: 30px;
      line-height: 1.5;
    }
  }

  .narrow-text {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  @media (max-width: 676px) {
    p {
      font-size: 14px;
    }

    .narrow-text {
      padding-left: 0px;
      padding-right: 0px;
    }

    .medium-text {
      text-align: left;
      font-size: 26px;
    }
  }
}

.black-section {
  background-color: $color-black;
  color: $color-white;

  .section-content {
    padding-top: 40px;
    padding-bottom: 130px;

    .text {
      text-align: center;
      margin-bottom: 70px;

      h3 {
        font-family: Roboto;
        font-size: 36px;
        font-weight: 400;
        line-height: 42px;
        letter-spacing: 0.51em;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 4rem;
      }

      @media (max-width: 767px) {
        h3 {
          font-size: 20px;
        }
      }

      p {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0.02em;
        text-align: center;
        padding-left: 20px;
        padding-right: 20px;

        br {
          display: none;
        }
      }
    }

    .outro {
      font-family: Roboto;
      font-size: 36px;
      font-weight: 100;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: center;
      color: $color-white;
      margin-top: 70px;
    }

    @media (max-width: 767px) {
      .outro {
        font-size: 18px;
        line-height: 1.4;

        br {
          display: none;
        }
      }
    }
  }
}

footer {
  @include animate-gradient;
  padding-top: 110px;

  .content {
    border-top: 0.8px solid rgba(255, 255, 255, 0.4);
    border-bottom: 0.8px solid rgba(255, 255, 255, 0.4);

    p {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 29px;
      letter-spacing: 0.51em;
      text-align: left;
      color: $color-white;
      text-transform: uppercase;
    }

    @media (max-width: 767px) {
      p {
        font-size: 16px;

        br {
          display: none;
        }
      }
    }
  }

  .back-to-top {
    @include transition;
    width: 75px;
    height: 75px;
    background-color: $color-white;
    color: $color-black;
    cursor: pointer;
    border-radius: 50%;
    margin-left: auto;
    margin-right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    opacity: 1;

    &:hover {
      opacity: 0.5;
    }

    &.mobile {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .back-to-top {
      margin-top: 25px;
      margin-left: auto;
      margin-right: auto;
      width: 50px;
      height: 50px;
      font-size: 20px;

      &.mobile {
        display: flex;
        margin-left: 0px;
      }

      &:not(.mobile) {
        display: none;
      }
    }
  }

  .contact {
    padding-top: 100px;
    padding-bottom: 100px;

    aside {
      padding-left: 124px;

      h3 {
        font-family: Roboto;
        font-size: 115px;
        font-weight: 800;
        line-height: 135px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: uppercase;
        color: $color-white;
      }

      p {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0em;
        text-align: left;
        color: $color-white;
        margin-bottom: 0px;
      }

      @media (max-width: 767px) {
        p {
          font-size: 14px;
          br {
            display: none;
          }
        }
      }

      .email-link {
        cursor: pointer;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        line-height: 23px;
        letter-spacing: 0.51em;
        text-align: left;
        color: $color-white;
        margin-bottom: 20px;
        display: inline-block;
      }

      @media (max-width: 767px) {
        .email-link {
          font-size: 14px;
          line-height: 1;
          letter-spacing: 2px;
        }
      }
    }

    @media (max-width: 767px) {
      aside {
        padding-left: 0px;

        h3 {
          font-size: 40px;
          line-height: 1;
          margin-bottom: 30px;
        }
      }
    }
  }

  .social {
    padding-bottom: 20px;

    .copyright {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
      text-align: left;
      color: $color-white;
    }

    @media (max-width: 767px) {
      .copyright {
        margin-bottom: 20px;
        display: block;
        margin-right: 10px;
        text-align: right;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: flex-end;

    li {
      a {
        display: block;
        padding: 0px 10px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: $color-white;
        text-decoration: none;
        text-transform: uppercase;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.off-canvas-menu {
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  flex-direction: column;

  .menu-logo {
    margin-bottom: 100px;
    text-align: center;

    svg {
      max-width: 80%;
    }
  }

  .off-canvas-close {
    @include transition;
    position: absolute;
    top: 25px;
    right: 25px;
    color: $color-white;
    background-color: transparent;
    border: none;
    font-size: 16px;

    &:hover {
      transform: scale(1.5);
    }
  }

  ul {
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    width: 60%;

    li {
      display: block;
      padding-top: 40px;
      padding-bottom: 40px;
      border-top: 0.8px solid $color-white;

      &:last-child {
        border-bottom: 0.8px solid $color-white;
      }

      button {
        @include transition;
        text-transform: uppercase;
        display: block;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        width: 100%;
        border: none;
        background: none;
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.51em;
        text-align: center;

        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }

  @media (max-width: 767px) {
    ul {
      width: 90%;

      li {
        padding-top: 30px;
        padding-bottom: 30px;

        button {
          font-size: 12px;
        }
      }
    }
  }
}

.section-content {
  padding-bottom: 4rem;
}
