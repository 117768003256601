@import "../../../assets/variables";

.services-list {
  .service-item {
    width: 320px;
    height: 320px;
    border-radius: 100%;
    background: linear-gradient(
      93.21deg,
      $accent-purple 3.76%,
      $accent-pink 36.27%,
      $accent-blue 78.47%,
      $accent-green 113.25%
    );
    position: relative;
    margin-top: 25px;
    margin-bottom: 25px;

    .inner {
      background-color: $color-white;
      color: $color-black;
      border-radius: 100%;
      position: absolute;
      width: 280px;
      height: 280px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h4 {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.1em;
        text-align: left;
        margin-right: auto;
        margin-left: 25px;
        transform: translateY(50%);
        text-transform: uppercase;
        max-width: 50%;
      }
    }

    .cover {
      @include transition;
      border-radius: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 320px;
      height: 320px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      z-index: 1;
      padding: 45px;
      background: linear-gradient(
        93.21deg,
        $accent-purple 3.76%,
        $accent-pink 36.27%,
        $accent-blue 78.47%,
        $accent-green 113.25%
      );
      opacity: 0;
      visibility: hidden;

      h4 {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.1em;
        text-align: left;
        text-transform: uppercase;
        margin-top: 0.5rem;
      }

      p {
        font-family: Roboto;
        font-size: 16px;
        font-weight: 100;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }

      button {
        @include transition;
        background: none;
        border: 1px solid $color-white;
        text-transform: uppercase;
        color: $color-white;
        font-size: 12px;
        padding: 5px 10px;
        border-radius: 25px;

        &:hover {
          background-color: $color-white;
          color: $color-black;
        }
      }
    }

    &.in {
      .cover {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media (max-width: 767px) {
    .service-item {
      width: calc(100% - 20px);
      height: unset;
      padding-top: calc(100% - 20px);

      .inner {
        width: calc(100% - 20px);
        height: calc(100% - 20px);

        h4 {
          font-size: 12px;
          max-width: 80%;
          transform: unset;
        }
      }

      .cover {
        width: 100%;
        height: 100%;
        padding: 20px;

        h4 {
          font-size: 12px;
        }

        p {
          display: none;
        }
      }
    }
  }
}
.service-modal {
  &.dark-modal {
    backdrop-filter: blur(5px);

    .modal-content {
      background-color: $color-black;
      color: $color-white;

      p {
        font-family: "roboto";
        font-weight: 300;
        font-size: 18px;
        text-align: justify;
      }

      .modal-header {
        .modal-title {
          font-family: "Roboto";
          text-transform: uppercase;
          font-weight: 300;
        }

        .btn-close {
          filter: var(--bs-btn-close-white-filter);
        }
      }

      @media (max-width: 767px) {
        p {
          height: 100%;
          margin: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
