@import "../../../assets/variables";

.section-title {
  padding-top: 6rem;
  padding-bottom: 3rem;

  &.section-title--black {
    color: $color-white;

    h2 {
      border-bottom-color: $color-white;
    }
  }

  h2 {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.51em;
    text-align: center;
    padding-bottom: 3rem;
    border-bottom: $border-width solid $line-gray;
  }
}
